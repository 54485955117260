<template>
  <div class="chargeoofline">
    <NavBar name="转移记录"></NavBar>
    <div class="chargeoofline-box" style="margin-top: 20px">
      <div style="margin: 15px 0">
        <MyInput placeholder="请输入收费人" v-model="params.contact_name" right>
          <template slot="pre">商户姓名:</template>
        </MyInput>
        <el-date-picker class="offset" v-model="date" @change="changeDate" format="yyyy-MM-dd" start-placeholder="申请开始时间"
          end-placeholder="申请结束时间" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>

        <MyButton left @click="currentChange(1)">
          搜索
          <template slot="preImage">
            <img src="../../../unit/img/search.png" alt />
          </template>
        </MyButton>
      </div>
      <div class="chargeoofline-table">
        <el-table :data="tableData" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }" v-loading="loading" height="95%">
          <el-table-column label="商户姓名" prop="contact_name"></el-table-column>
          <el-table-column label="原转移项" prop="balance_name"></el-table-column>
          <el-table-column label="转移对象" prop="fee_name"></el-table-column>
          <el-table-column label="转移金额" prop="balance_money"></el-table-column>
          <el-table-column label="申请时间" prop="create_at"></el-table-column>
          <el-table-column label="状态" prop="is_agree">
            <template #default="{ row }">
              {{ agreeMap(row.is_agree) }}
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remarks"></el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <pop tips="查看" :accessId="86842" @myclick="hanldeDetail(row, 0)">
                <img src="../../../unit/img/xq.png" class="icon" alt />
              </pop>
              <pop tips="审核" :accessId="86842" @myclick="hanldeDetail(row, 1)">
                <img v-if="row.is_agree == 10" src="../../../unit/img/sh.png" class="icon" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
        <div class="charageoff-page">
          <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
            :page-size="params.pageSize" :current-page="params.pageNum" @current-change="currentChange"></el-pagination>
        </div>
        <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="visible" width="880px">
          <el-form ref="formDataRef" label-width="110px" v-if="detailData" :model="detailData" :rules="rules"
            :validate-on-rule-change="false">
            <el-row>
              <el-col :span="24">
                <el-form-item label="原余额名称:">{{ detailData.balance_name }}</el-form-item></el-col>
              <el-col :span="24">
                <el-form-item label="转入目标:">{{ detailData.fee_name }}</el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="转移金额:">{{
                  detailData.balance_money
                }}</el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注:">{{
                  detailData.remarks
                }}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="凭证:">
                  <div class="imgBox" v-if="detailData.images.length > 0">
                    <div class="imgList" v-for="(item, index) in detailData.images" :key="index">
                      <img :src="imgUrl + item" alt="" />
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="审核意见:" prop="agree_desc" v0>
                  <el-input @blur="hanldeBlur" :disabled="mode == 0" v-model="detailData.agree_desc" type="textarea"
                    :rows="2" :placeholder="mode == 0 ? '' : '请输入审核意见'" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer" v-if="mode == 0">
            <MyButton @click="close" right>取 消</MyButton>
            <MyButton type="primary" @click="close">确 定</MyButton>
          </div>
          <div slot="footer" class="dialog-footer" v-if="mode == 1">
            <MyButton @click="close" right>取 消</MyButton>
            <MyButton @click="hanldeApprove(30)" right>拒 绝</MyButton>
            <MyButton type="primary" @click="hanldeApprove(20)">同 意</MyButton>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    const checkDesc = (rules, value, callback) => {
      if (this.mode == 0) {
        callback();
      }
      if (!value && this.isCheck) {
        callback(new Error("请输入拒绝理由"));
      } else {
        callback();
      }
    };
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      imgUrl: process.env.VUE_APP_IMG_URL,
      date: [],
      total: 0,
      tableData: [],
      loading: false,
      detailData: null,
      visible: false,
      mode: 0, //弹窗类型 :0详情 1审核
      title: "详情",
      rules: {
        agree_desc: [{ validator: checkDesc }],
      },
      isCheck: false, //是否校验拒绝备注
    };
  },
  created () {
    this.getList();
  },
  methods: {
    agreeMap (type) {
      let str = "";
      switch (type) {
        case 10:
          str = "未审核";
          break;
        case 20:
          str = "同意";
          break;
        case 30:
          str = "拒绝";
          break;

        default:
          break;
      }
      return str;
    },
    hanldeDetail (row, type) {
      try {
        this.detailData = {
          ...row,
          images: row.images ? JSON.parse(row.images) : []
        };
      } catch (error) {
        this.detailData = {
          ...row,
          images: []
        };
      }


      this.visible = true;
      switch (type) {
        case 0:
          this.title = "详情";
          this.mode = 0;
          break;
        case 1:
          this.title = "审核";
          this.mode = 1;
          break;

        default:
          break;
      }
    },
    close () {
      this.visible = false;
    },
    hanldeBlur () {
      this.isCheck = false;
    },
    hanldeApprove (result) {
      if (result == 30) {
        this.isCheck = true;
        this.$refs.formDataRef.validate((valid) => {
          console.log(valid, "valid");
          if (valid) {
            this.submit(result);
          }
        });
      } else {
        this.submit(result);
      }
    },
    submit (result) {
      const params = {
        balance_change_id: this.detailData.balance_change_id,
        is_agree: result,
        agree_desc: this.detailData.agree_desc,
      };
      this.$request
        .HttpPost("/merchants_balance_change/isAgree", params)
        .then(() => {
          this.$common.notifySuccess("操作成功");
          this.visible = false;
          this.getList();
        });
    },

    changeDate (date) {
      if (date) {
        this.params.beginTime = date[0];
        this.params.endTime = date[1];
      } else {
        this.params.beginTime = "";
        this.params.endTime = "";
      }
    },
    currentChange (page) {
      this.params.pageNum = page;
      this.getList();
    },
    getList () {
      this.loading = true;
      this.$request
        .HttpPost("/merchants_balance_change/list", this.params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list || [];
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.chargeoofline-box {
  height: calc(100vh - 205px);
}

.chargeoofline-table {
  height: 80%;
}

.charageoff-page {
  text-align: right;
  margin-top: 40px;
}

.icon {
  margin-right: 20px;
}

.imgBox {
  display: flex;

  .imgList {
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 110px;
    height: 110px;

    i {
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      width: 110px;
      height: 110px;
    }
  }
}
</style>
